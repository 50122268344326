<template>
    <b-row>
      <b-col
        xl="12"
      >
        <h5 class="mb-2">LAPORAN HASIL PEMERIKSAAN</h5>
        <b-form-group
          label="No Register"
          label-for="no_register"
        >
          <b-form-input
            id="no_register"
            trim
            placeholder=""
            :disabled="true"
            v-model="localData.no_register"
          />
        </b-form-group>
       <b-form-group
          label="Tanggal"
          label-for="tanggal"
        >
          <b-form-datepicker id="tanggal" v-model="localData.tanggal_dokumen" locale="id" class="mb-2"></b-form-datepicker>
        </b-form-group>
       </b-col>
       <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Jenis Dokumen</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            :options="jenisDokumenOptions"
            v-model="localData.jenis_dokumen_id"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="mt-2"
        >
            <b-button class="btn" variant="outline-primary" :disabled="localData.jenis_dokumen_id === null">Unduh Template</b-button>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
            <b-media no-body>
              <b-media-body class="mt-60 ml-60">
                <label>File</label>
                <div class="d-flex" style="width: 100%;">
                  <b-form-file
                    v-if="!$route.params.readonly"
                    v-model="localData.file"
                    placeholder="Masukkan File"
                  >
                  </b-form-file>
                </div>
                <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
              </b-media-body>
            </b-media>
        </b-col>
        <div class="d-flex justify-content-begin">
          <b-col
            cols="12"
            md="3"
            class="mt-2 mr-4"
            v-if="localData.dokumen_lhp.nama_file"
          >
              <b-button class="btn" variant="outline-success" @click="downloadDokumenLHP">Download</b-button>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mt-2"
          >
              <b-button class="btn" variant="outline-primary" :disabled="localData.tanggal_dokumen === null || localData.file === null" @click="simpanDokumen">Simpan</b-button>
          </b-col>
        </div>
       <!-- <b-col xl="12" class="mt-3">
        <b-tabs pills>
          <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pelapor</span>
              </template>
              <pelapor />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kuasa Pelapor</span>
              </template>
              <kuasa-pelapor />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Terlapor</span>
              </template>
              <terlapor />
            </b-tab>
        </b-tabs>
       </b-col>
       <b-col xl="12" class="mt-3">
        <b-tabs pills>
          <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dasar Pemeriksaan</span>
              </template>
              <dasar-pemeriksaan />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kasus Posisi</span>
              </template>
              <kasus-posisi />
            </b-tab>
          <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pokok Laporan</span>
              </template>
              <pokok-laporan />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Tujuan Pemeriksaan</span>
              </template>
              <tujuan-pemeriksaan />
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Pelaksanaan Pemeriksaan</span>
              </template>
              <pelaksanaan-pemeriksaan />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Hasil Pemeriksaan</span>
              </template>
              <hasil-pemeriksaan />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Analisis</span>
              </template>
              <analisis />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Kesimpulan</span>
              </template>
              <kesimpulan />
            </b-tab>
             <b-tab>
              <template #title>
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Tanda Tangan</span>
              </template>
              <tanda-tangan />
            </b-tab>
        </b-tabs>
       </b-col> -->
       <b-col xl="12" class="mt-3 mb-3">
        <!-- <div class="d-flex justify-content-end">
          <b-button variant="outline-primary" v-b-modal.modal_confirm_tki>Teruskan ke Investigasi & Advokasi</b-button>
        </div> -->
        <div>
          <b-modal id="modal_confirm_tki" title="Konfirmasi" centered @ok="update('investigasi-advokasi')">
            <div class="d-flex justify-content-center" >
              <h4 class="my-4" style="text-align: center;">Apakah anda yakin laporan diteruskan ke investigasi?</h4>
            </div>
          </b-modal>
        </div>
      </b-col>
    </b-row>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Kerjasama from '@/views/laporan/list/List.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BModal,
  BFormFile,
  BMedia,
  BMediaBody,
  BFormCheckbox,
  BTab,
  BTabs,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'
import {
  hapusPihak,
} from '@/connection-api/laporan'
import {
  updateVerifikasi,
  uploadFileLHP,
  downloadFileLHP,
  updateLHP,
} from '@/connection-api/master'
import { required, email } from '@validations'
import flatPickr from "vue-flatpickr-component";
import { Indonesian } from "flatpickr/dist/l10n/id.js";
import 'flatpickr/dist/flatpickr.css';
import ModalInstansi from "./ModalInstansi.vue"
import ModalNarahubung from "./ModalNarahubung.vue"
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import KasusPosisi from '@/views/kasus-posisi/list/List.vue'
import PokokLaporan from '@/views/pokok-laporan/list/List.vue'
import DataPendukung from '@/views/data-pendukung/list/List.vue'
import Analisis from '@/views/analisis/list/List.vue'
import Kesimpulan from '@/views/kesimpulan/list/List.vue'
import SaranAnalisis from '@/views/saran-analisis/list/List.vue'
import TandaTangan from '@/views/tanda-tangan/list/List.vue'
import PertanyaanJawaban from '@/views/pertanyaan-jawaban/list/List.vue'
import DasarPemeriksaan from '@/views/dasar-pemeriksaan/list/List.vue'
import TujuanPemeriksaan from '@/views/tujuan-pemeriksaan/list/List.vue'
import HasilPemeriksaan from '@/views/hasil-pemeriksaan/list/List.vue'
import PelaksanaanPemeriksaan from '@/views/pelaksanaan-pemeriksaan/list/List.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
    flatPickr,
    BModal,
    BFormFile,
    BMedia,
    BMediaBody,
    Kerjasama,
    ModalInstansi,
    ModalNarahubung,
    KasusPosisi,
     BFormCheckbox,
    BTab,
    BTabs,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    PokokLaporan,
    DataPendukung,
    Analisis,
    Kesimpulan,
    SaranAnalisis,
    TandaTangan,
    BFormDatepicker,
    BFormTimepicker,
    PertanyaanJawaban,
    DasarPemeriksaan,
    TujuanPemeriksaan,
    HasilPemeriksaan,
    PelaksanaanPemeriksaan,
  },
  data() {
    return {
      configs: {
        basic: {},
        wrap: {
          wrap: true,
          dateFormat: "d-m-Y",
          locale: Indonesian,
        },
      },
      required,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  props: {
    localData: {},
    pemeriksaanOptions: {},
    jenisDokumenOptions: {},
  },
  methods: {
    async downloadDokumenLHP(){
      const result = await downloadFileLHP(this.localData.dokumen_lhp.nama_file)
      const url = window.URL.createObjectURL(new Blob([result.data]))
      const link = document.createElement('a')
      const date = new Date().toLocaleString(['ban', 'id'])
      link.href = url
      link.setAttribute('download', `LPP-${date}.docx`)
      document.body.appendChild(link)
      link.click()
    },
    async simpanDokumen(){
       if(this.localData.file != null)
        {
          const responseUpload = await uploadFileLHP(this.localData.file, event => {
            this.progress = Math.round((100 * event.loaded) / event.total)
          })
          this.localData.nama_file = responseUpload.data.file
        }

        if(this.localData.nama_file != null || this.localData.dokumen_lhp.nama_file)
        {
          this.localData.laporan_id = this.$route.params.laporan_id
          const response = await updateLHP(this.localData)
          if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LHP',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.localData.dokumen_lhp = response.data.data
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LHP',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.message
          }
        } else {
           this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'LHP',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: responseUpload.data.message,
                },
              })
              this.error = responseUpload.data.message
        }
        
    },
    async update(params) {
      switch (params) {
        case 'investigasi-advokasi':
          this.localData.jenis_cta = "investigasi-advokasi";
          break;
        case 'pemeriksaan-pendahuluan':
          this.localData.jenis_cta = 'pemeriksaan-pendahuluan';
          break;
      }
      
      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await updateVerifikasi(this.localData)
      if(response.data.success === true)
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.$emit('refetchData')
              this.$router.push({name: 'tpl-analisis-laporan'})
          }
          else
          {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Analisis Laporan',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
              this.error = response.data.error
          }
    },
    updateParaPihak() {
      this.$emit('emitUpdateParaPihak')
    },
    async hapusData(id){
      if(window.confirm('Apakah anda yakin?'))
      {
        const resp = await hapusPihak(id)
        if(resp.data.success === true)
        {
          this.makeToast('success', resp.data.message)
          this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
        }
      }
    },
    reloadDataKerjasama()
    {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Kerjasama',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  }
}
</script>
